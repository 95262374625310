<template>
  <div class="media-funnel-container">
    <homeTitle class="home_title" title="数据漏斗" borderColor="#f40" />
    <div class="funnel-box">
      <funnelItem
        :length="4"
        :index="0"
        :color="colorList[0]"
        title="请求"
        :count="datas.request || 0"
        ratioTitle="填充率"
        :ratio="datas.requestFilledRate || 0"
        :chain="datas.requestFilledRatio || 0"
      />
      <funnelItem
        :length="4"
        :index="1"
        :color="colorList[1]"
        title="	填充"
        :count="datas.response || 0"
        ratioTitle="流量填充率"
        :ratio="''"
        :chain="''"
      />
      <funnelItem
        :length="4"
        :index="2"
        :color="colorList[2]"
        title="展示"
        :count="datas.impress || 0"
        ratioTitle="点击率"
        :ratio="datas.clickRate || 0"
        :chain="datas.clickRatio || 0"
      />
      <funnelItem
        :length="4"
        :index="3"
        :color="colorList[3]"
        title="点击"
        :count="datas.click || 0"
        ratioTitle="点击率"
        :ratio="0"
        :chain="0"
      />
    </div>
  </div>
</template>

<script>
import funnelItem from './funnelItem.vue'
import { getFunnelData } from '@/api/mediaDashboard'
import homeTitle from '../../homeTitle'
export default {
  components: { funnelItem, homeTitle },
  data () {
    return {
      colorList: ['#edf3fe', '#e3edfe', '#d6e4fd', '#c8dbfc'],
      datas: {}
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      const resp = await getFunnelData()
      this.datas = resp.data
    }
  }
}
</script>

<style lang="less" scoped>
.media-funnel-container {
  height: 100%;
  padding-top: 10px;
  .home_title {
    position: absolute;
    margin-left: -10px;
    top: 0;
  }
  .funnel-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: scale(0.9);
  }
}
</style>

<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-01-11 11:57:10
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-01-16 17:25:37
 * @FilePath: /mediatom-web/src/views/mediadashboard/components/siderCharts/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="sider-charts-container">
    <div class="sider-charts-box">
      <div class="head">
        <a-dropdown>
          <span
            class="title"> {{ currentTitle }} <a-icon type="down" /> </span>
          <a-menu slot="overlay" :selectedKeys="[currentChart]" class="menuBox">
            <a-menu-item class="menu-item" v-for="(item) in dimensionList" :key="item.component" @click="clickMenu(item)">
              {{ item.name }}
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
      <div class="side-chart-top">
        <PlatPie v-if="currentChart === 'PlatPie'"/>
        <PositionPie v-if="currentChart === 'PositionPie'"/>
      </div>
      <div class="side-funnel-bottom">
        <MediaFunnel />
      </div>
    </div>
  </div>
</template>

<script>
import PlatPie from './PlatPie'
import PositionPie from './PositionPie'
import MediaFunnel from './mediaFunnel'
import { getMediaReportRuleVo } from '@/api/reportdatas'
export default {
  components: { PlatPie, MediaFunnel, PositionPie },
  data () {
    return {
      currentChart: 'PositionPie',
      dimension: []
    }
  },
  computed: {
    currentTitle () {
      return this.dimensionList.find((item) => item.component === this.currentChart).name
    },
    dimensionList () {
      if (this.dimension.includes('platName')) {
        return [
        {
          name: 'Top广告平台',
          component: 'PlatPie'
        },
        {
          name: 'Top广告样式',
          component: 'PositionPie'
        }
      ]
      } else {
        return [
        {
          name: 'Top广告样式',
          component: 'PositionPie'
        }
      ]
      }
    }
  },
  created () {
    this.getRule()
  },
  methods: {
    clickMenu (item) {
      this.currentChart = item.component
    },
    async getRule () {
      const { data = {} } = await getMediaReportRuleVo()
      const { dimension = [] } = data
      this.dimension = dimension
    }
  }
}
</script>
<style lang="less" scoped>
.sider-charts-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .head{
    padding: 10px 15px;
    .title{
      font-weight: 600;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        height: 12px;
        width: 3px;
        top: 3px;
        left: -7px;
        background-color: red;
      }
    }
  }
  .sider-charts-box {
    margin: 0 10px 10px 10px;
    flex-grow: 1;
    height: 0;
    display: flex;
    flex-direction: column;
    .side-chart-top {
      height: 42%;
      border-bottom: 1px solid #ccc;
    }
    .side-funnel-bottom {
      flex-grow: 1;
      height: 0;
      position: relative;
    }
  }
}
</style>

<template>
  <div class="media-pie-plat-chart-container">
    <a-spin :spinning="spinning">
      <div class="chart" ref="chart"></div>
    </a-spin>
  </div>
</template>

<script>
import HomeTitle from '../../homeTitle.vue'
import { getPositionPie } from '@/api/mediaDashboard'
import option from './options'
import { numFormat } from '@/utils/dealNumber'
import mixDate from '@/mixins/initDate'
import * as echarts from 'echarts'
export default {
  components: { HomeTitle },
  data () {
    return {
      spinning: false,
      option: {},
      colorList: ['#3de4a9', '#3b78ff', '#7451de', '#bd58f7', '#77a5eb'],
      myEcharts: null,
      datetype: 'b'
    }
  },
  mixins: [mixDate],
  created () {
    this.option = JSON.parse(JSON.stringify(option))
  },
  mounted () {
    this.myEcharts = echarts.init(this.$refs.chart, null, { render: 'svg' })
    this.initChart()
    window.addEventListener('resize', this.getSize)
    this.getChartsData()
  },
  destroyed () {
    this.myEcharts.dispose()
    window.removeEventListener('resize', this.getSize)
  },
  methods: {
    getSize () {
      // 判断是否是created时调用
      if (this.myEcharts) {
        setTimeout(() => {
          this.myEcharts.resize()
        }, 100)
      }
    },
    initChart () {
      this.myEcharts.setOption(this.option)
    },
    getLength (s) {
      const str = s.replace(/[\u0391-\uFFE5]/g, 'aa')
      return str.length
    },
    async getChartsData () {
      this.spinning = true
      const resp = await getPositionPie()
      const sum = resp.data.unitRevenueSum
      this.option.series[0].label.normal.formatter = `总收益\n${numFormat(sum, 2, '')}`
      this.option.legend.formatter = (name, a) => {
        const datas = resp.data.list
        const unitRevenue = datas.filter((item) => item.positionName === name)[0].unitRevenue
        const propor = unitRevenue / sum

        var arr = ['{a|' + name + '}', '{b|' + (sum === 0 ? 0 : (propor * 100).toFixed(2)) + '%}']
        return arr.join('  ')
      }
      this.option.legend.textStyle = {
        // 添加
        // padding: [8, 0, 0, 0],
        rich: {
          a: {
            fontSize: 12,
            width: 60,
            color: '#888'
          },
          b: {
            fontSize: 12,
            width: 70
          }
        }
      }
      this.option.tooltip.formatter = (params) => {
        return (
          params.marker +
          '<span>&nbsp;&nbsp;&nbsp;</ span>' +
          params.name +
          '<span>&nbsp;&nbsp;&nbsp;</ span>￥' +
          numFormat(params.data.value, 2, '') +
          '<span>&nbsp;&nbsp;&nbsp;</ span>' +
          (!params.data.value ? 0 : sum === 0 ? 0 : ((params.data.value / sum) * 100).toFixed(2)) +
          '%'
        )
      }
      this.option.tooltip.position = (point, params, dom, rect, size) => {
        var x = 0 // x坐标位置
        var y = 0 // y坐标位置
        // 当前鼠标位置
        var pointX = point[0]
        var pointY = point[1]
        // 提示框大小
        var boxWidth = size.contentSize[0]
        var boxHeight = size.contentSize[1]

        // boxWidth > pointX 说明鼠标左边放不下提示框
        if (boxWidth > pointX) {
          x = pointX + 10
        } else {
          // 左边放的下
          x = pointX - boxWidth - 10
        }

        // boxHeight > pointY 说明鼠标上边放不下提示框
        if (boxHeight > pointY) {
          y = 5
        } else {
          // 上边放得下
          y = pointY - boxHeight
        }
        return [x, y]
      }
      this.option.series[0].data = resp.data.list.length > 0
        ? resp.data.list.map((item, i) => {
            return {
              value: item.unitRevenue,
              name: item.positionName,
              itemStyle: {
                color: this.colorList[i]
              }
            }
          })
        : [
            {
              value: 0,
              name: '',
              itemStyle: {
                color: this.colorList[0]
              }
            }
          ]
      this.spinning = false
      this.initChart()
    }
  }
}
</script>
<style lang="less">
@import '~@/assets/less/mixins/redefineAnt.less';
.media-pie-plat-chart-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  height: 100%;
  .control {
    width: 100%;
    padding: 0 5px;
    display: flex;
    justify-content: space-between;
    .date_radio {
      margin-top: 20px;
      margin-right: 20px;
    }
  }
  .ant-spin-nested-loading {
    flex-grow: 1;
    height: 0;
    position: relative;
    .ant-spin-container {
      height: 100%;
      .chart {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.media-pie-plat-chart-container {
  .aRadioBtn();
}
</style>
